/* You can add global styles to this file, and also import other style files */

:root {
    --gutter: 1rem;
  } /* for primeflex grid system

/* Layout */
@import '../../../libs/assets/layout/styles/layout/layout.css';

/* PrimeNG */
@import '../../../node_modules/primeng/resources/primeng.min.css';
@import '../../../node_modules/primeflex/primeflex.css';
@import '../../../node_modules/primeicons/primeicons.css';

/* Quill Text Editor for p-editor */
@import '../../../node_modules/quill/dist/quill.core.css';
@import '../../../node_modules/quill/dist/quill.snow.css';

body, .p-button {
  font-family: 'Gilroy', sans-serif;
}

/* primeng components scaling */
html {
  font-size: 12px;
}
/* primeng components scaling */

// utils
  .bg-success-25 { background-color: var(--success-25); }
  .bg-success-50 { background-color: var(--success-50); }
  .bg-success-100 { background-color: var(--success-100); }
  .bg-success-200 { background-color: var(--success-200); }
  .bg-success-300 { background-color: var(--success-300); }
  .bg-success-400 { background-color: var(--success-400); }
  .bg-success-500 { background-color: var(--success-500); }
  .bg-success-600 { background-color: var(--success-600); }
  .bg-success-700 { background-color: var(--success-700); }
  .bg-success-800 { background-color: var(--success-800); }
  .bg-success-900 { background-color: var(--success-900); }

  .bg-warning-25 { background-color: var(--warning-25); }
  .bg-warning-50 { background-color: var(--warning-50); }
  .bg-warning-100 { background-color: var(--warning-100); }
  .bg-warning-200 { background-color: var(--warning-200); }
  .bg-warning-300 { background-color: var(--warning-300); }
  .bg-warning-400 { background-color: var(--warning-400); }
  .bg-warning-500 { background-color: var(--warning-500); }
  .bg-warning-600 { background-color: var(--warning-600); }
  .bg-warning-700 { background-color: var(--warning-700); }
  .bg-warning-800 { background-color: var(--warning-800); }
  .bg-warning-900 { background-color: var(--warning-900); }
// utils

.dialog-header {
  align-items: center;
}

.dialog-title {
  flex: 1;
}

.custom-dialog-position {
  top: -5%;
}

.button-container {
  display: inline-block;
}

// :root {
//   --background-color: #181542;
//   --primary-color: #3e50f7;
//   --default-spacing: 16px;
//   --white-color: #fff;
//   --black-color: #000;
//   --dark-orange:#EDAE4929;
//   --orange:#EDAE49;
//   --dark-blue:#1D1A4A;
//   --light-blue:#60608A;
//   --light-red:#db5461;
//   --Very-dark:#100e31;
// }

.page-section {
  background-color: var(--background-color);
  min-height: 100vh;
}

.page-section-ref {
  margin: auto;
  max-width: 375px;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.active {
  background-color: var(--primary-color) !important;
}

.p-bottom-sticky {
  position: sticky;
  bottom: 0;
  background-color: var(--background-color);
  margin-top: auto;
}

.p-top-sticky {
  position: sticky;
  top: 0;
  background-color: var(--background-color);
}

input,
.p-inputgroup-addon,
textarea {
  background-color: var(--formControlBg-color) !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.p-inputtext, input.p-inputtext, .p-dropdown {
  background-color: var(--formControlBg-color) !important;
  font-weight: 500;
}

.p-inputgroup .p-inputtext {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.p-inputtext:focus {
  box-shadow: none;
}

.p-inputtext:-webkit-autofill {
  background-color: brown;
}

.tech-dialog .p-dialog-title {
  padding-left: 2rem;
  font-size: 2rem !important;
}

.p-menu-overlay {
  max-height: 13rem;
  overflow-y: auto;
}

.p-input-icon-right > .p-icon-wrapper{
  right: 3%;
}

.brdr-color {
  border-color: var(--border-clr);
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
  border-bottom-style: solid;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
  border-bottom-style: solid;
}

.border-right-4 {
  border-right-width: 4px !important;
  border-right-style: solid;
}

.border-right-5 {
  border-right-width: 5px !important;
  border-right-style: solid;
}

.border-left-4 {
  border-left-width: 4px !important;
  border-left-style: solid;
}

.border-left-5 {
  border-left-width: 5px !important;
  border-left-style: solid;
}

.border-top-4 {
  border-top-width: 4px !important;
  border-top-style: solid;
}

.border-top-5 {
  border-top-width: 5px !important;
  border-top-style: solid;
}

.p-datatable.leaderboard-table {
  font-weight: 500;

  .p-datatable-header,
  .p-datatable-tbody > tr > td,
  .p-datatable-thead > tr > th {
      padding: 1.35rem 1.85rem;
  }


  .p-paginator {
      .p-paginator-first,
      .p-paginator-last {
          display: none;
      }

      .p-paginator-prev {
          margin-right: auto;
      }

      .p-paginator-next {
          margin-left: auto;
      }

    .p-button.p-button-text {
      color: var(--surface-900);
    }
  }
}

@media screen and (max-width: 992px) {
  .leaderboard-table > div > table > .p-datatable-tbody > tr > td > .p-column-title {
    display: block;
  }

  .leaderboard-table > div > table > .p-datatable-tbody > tr > td {
    display: flex;
    width: 100% !important;
    align-items: center;
  }
  .leaderboard-table > div > table > .p-datatable-thead > tr > th,
  .leaderboard-table > div > table > .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .leaderboard-table .p-datatable-tbody > tr > td  {
    border-width: 0;
  }

  .leaderboard-table .p-datatable-tbody > tr > td {
    border-width: 0 1px 0 1px;
  }

  .leaderboard-table .p-datatable-tbody > tr > td:first-child {
    border-width: 1px 1px 0 1px;
    border-top-right-radius: 0.65rem;
    border-top-left-radius: 0.65rem;
    margin-top: 0.7rem;
  }

  .leaderboard-table .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px 1px;
    border-bottom-left-radius: 0.65rem;
    border-bottom-right-radius: 0.65rem;
    margin-bottom: 0.7rem;
  }
  // .leaderboard-table .p-datatable-wrapper {
  //     margin: 1.5rem 0 2rem;
  //     padding: 0.75rem 0 0;
  // }

  .leaderboard-table.p-datatable .p-datatable-header,
  .leaderboard-table.p-datatable .p-datatable-tbody > tr > td,
  .leaderboard-table.p-datatable .p-datatable-thead > tr > th {
      padding: 0.75rem 1rem;
  }

  .leaderboard-table.p-datatable .p-datatable-header {
    border-width: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .leaderboard-table.p-datatable .p-paginator-bottom {
    border-width: 0;
  }

  .leaderboard-table.p-datatable .p-datatable-tbody > tr {
    position: relative;
    top: -0.5rem;
  }

  .leaderboard-table.p-datatable .p-datatable-tbody > tr > td > span:last-child{
    color: var(--surface-400);
  }

  input[pinputtext][ngxdaterangepickermd] {
    max-width: 100%;
  }
}

#form-element .finix-form-container .field-holder {
  background: none;
}

#form-element {
  height: 100%;
  .finix-form-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      .finix-submit-button {
        font-weight: 600;
        margin-top: auto !important;
        font-family: 'Gilroy';
      }
  }
}

#form-element .finix-form-container .finix-submit-button {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--border-clr) !important;
  color: var(--surface-900) !important;
}

#form-element .finix-form-container .finix-submit-button:disabled {
  opacity: 0.4;
}

.p-top-sticky {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: var(--bluegray-900);
}

.p-bottom-sticky {
  position: sticky;
  bottom: 0;
  background-color: var(--bluegray-900);
  margin-top: auto;
  z-index: 9;
}

.page-section {
  background-color: var(--bluegray-900);
  min-height: 100vh;
}

.blue-dialog.p-dialog .p-dialog-content,
.blue-dialog.p-dialog .p-dialog-header {
  background-color: var(--bluegray-500);
}

// date range picker
.date-filter.p-input-icon-right {
  .p-inputtext {
      padding-left: 2rem;
      padding-right: 0;
      min-width: 15rem;
  }

  .p-icon-wrapper {
      left: 4%;
  }
}

.md-drppicker.shown.drops-down-left {
  display: flex;
  border-radius: 6px;
}

@media screen and (max-width: 992px) {
  .md-drppicker.shown.drops-down-left {
    display: block;
  }
}
// date range picker

.white-dialog.p-dropdown-panel {
  background: var(--surface-900);
  .p-dropdown-items .p-dropdown-item,
  .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: var(--gray-400)
  }
}

.white-dialog {
  .leaderboard-table td,
  .leaderboard-table th,
  .p-paginator {
    border-color: var(--gray-100) !important;
  }

  .leaderboard-table th,
  .leaderboard-table td {
    color: var(--gray-400) !important;
    font-weight: 500;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    background-color: #F9FAFB !important;

    .p-checkbox .p-checkbox-box {
      border-radius: 6px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--Base-White, #FFF);
    }
  }

  .leaderboard-table td {
    font-weight: 400;
  }

  .leaderboard-table .p-datatable-header {
    border-color: var(--gray-100) !important;
  }

  label {
    color: var(--gray-700);
  }

  .p-radiobutton .p-radiobutton-box {
    border-width: 1px;
    background: none;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: var(--primary-color);
  }

  .p-checkbox .p-checkbox-box {
    background-color: transparent;
    border-width: 1px;
    border-radius: 50%;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    background-color: var(--primary-color) !important;
  }
}

.filter-overlay .p-overlaypanel-content {
  min-width: 55rem;
}

.layout-breadcrumb ol li a {
  color: var(--text-color-secondary);
}

.layout-breadcrumb ol li:last-child {
  color: var(--surface-900);
  font-weight: 600;
}

.gpay-card-info-container {
  width: 100%;
}

.gpay-card-info-iframe {
  width: 100%;
}

apple-pay-button {
  --apple-pay-button-width: 370px;
  --apple-pay-button-height: 40px;
  --apple-pay-button-border-radius: 4px;
  --apple-pay-button-padding: 6px 0px;
  --apple-pay-button-box-sizing: border-box;
}

.field-type .p-overlaypanel-content {
  padding: 0;
}

.field-type .p-overlaypanel-content .p-listbox {
  background: var(--surface-50);
}

.verify-flow-editor {
  .p-editor-toolbar.ql-snow {
    border: 1px solid var(--border-clr);
    padding: 1.5rem;
  }

  .p-editor-content .ql-editor {
    background: transparent;
  }

  .p-editor-content.ql-snow {
    border: 1px solid var(--border-clr);
  }
}

// date picker
.md-drppicker {
  background-color: var(--bluegray-500) !important;
  padding: 2rem 2rem 4rem 2rem !important;
  font-family: Gilroy;
  position: relative;
}

.md-drppicker .calendar-table,
.md-drppicker .calendar td {
  background-color: var(--bluegray-500) !important;
}
.md-drppicker .calendar-table > table {
  border-spacing: 0px 2px;
}

.md-drppicker.ltr .calendar.left .calendar-table,
.md-drppicker.ltr .calendar.right .calendar-table {
  border: 0;
}

.md-drppicker td.available.prev, .md-drppicker th.available.prev,
.md-drppicker th {
  color: var(--gray-500) !important;
  font-weight: 500;
}
.md-drppicker .calendar td {
  color: var(--gray-400) !important;
  min-width: 40px !important;
  height: 40px;
}

.md-drppicker td.active.start-date,
.md-drppicker td.active.in-range.end-date {
  background-color: var(--primary-color) !important;
  color: var(--surface-900) !important;
}

.md-drppicker td.off,
.md-drppicker td.active.start-date.off,
.md-drppicker td.active.end-date.off {
  background-color: var(--bluegray-500) !important;
  color: var(--gray-500) !important;
}

.md-drppicker td.in-range {
  background-color: var(--bluegray-300) !important;
  color: var(--gray-400) !important;
}

.md-drppicker .calendar {
  max-width: max-content !important;
}

.md-drppicker .buttons {
  width: 0;
  position: absolute;
  bottom: 1rem;
  right: 7rem;
}

.md-drppicker .btn {
  padding: 0 1.5rem !important;
  background-color: var(--primary-color) !important;
  font-family: Gilroy;
  font-weight: 500;
  text-transform: capitalize !important;
  font-weight: 600;
  border-radius: 8px !important;
}

.md-drppicker th.month {
  font-weight: 600;
}

.md-drppicker .ranges ul li button {
  font-family: Gilroy;
  color: var(--gray-400);
}

.md-drppicker.ltr .ranges {
  min-width: 10rem;
}

.md-drppicker .ranges ul li button.active {
  border-radius: 6px;
  font-weight: 500;
}

.md-drppicker td.available.in-range:hover, .md-drppicker th.available.in-range:hover {
  border-radius: 0 !important;
}

.md-drppicker td.available:not(.in-range):hover, .md-drppicker th.available:not(.in-range):hover {
  background-color: var(--bluegray-300) !important;
}

input[pinputtext][ngxdaterangepickermd] {
  width: 18rem;
}

@media screen and (max-width: 992px) {
  // .md-drppicker.shown.drops-down-left {
  //   right: -75px !important;
  // }
  input[pinputtext][ngxdaterangepickermd],
  p-iconfield {
    width: 100% !important;
  }
}

.header-filter-field.p-dropdown {
  .p-dropdown-label > div > div,
  .p-dropdown-trigger  {
    color: var(--surface-400);
  }
}

.header-filter-field.p-multiselect {
  .p-multiselect-label > div > div,
  .p-multiselect-trigger  {
    color: var(--surface-400);
  }
}


.metric-tooltip {
  border: 0 !important;
  background-color: var(--surface-50);
  max-width: 240px;
}

.source-dropdown .p-dropdown-item.p-disabled {
  opacity: 1 !important;
}

.global-loader {
  position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    background-color: #9a9a9a1f;
}

.fnt-1 {
  font-family: Piedmont;
}

.fnt-2 {
  font-family: Parisienne;
}

.fnt-3 {
  font-family: Roundhand;
}

.fnt-4 {
  font-family: Gilroy;
}

.fnt-5 {
  font-family: "Canela Trial";
}

.ctm-fltr {
  border-radius: 8px;
  border: 1px solid var(--bluegray-300);
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background-color: var(--surface-50) !important;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
}

.ctm-fltr:focus-visible {
  outline: 0px !important;
}
.field-staff-options {
  min-width: 233px;
}

.tooltip-container {
  h5 {
      margin-top: 0;
      color: var(--gray-600);
  }
  ul, ol {
    padding-inline-start: 1rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
}

.p-dialog .p-dialog-header-icons {
  z-index: 2;
}